// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('transactions/getData', async params => {
  const response = await axios.get('/payment/', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getStats = createAsyncThunk('payments/getStats', async params => {
  const response = await axios.get('/payment/stats/', { params })
  return {
    data: response.data
  }
})

export const refund = createAsyncThunk('payments/refund', async params => {
  const response = await axios.post('/payment/refund/', params)
  return {
    data: response.data
  }
})


export const appUsersSlice = createSlice({
  name: 'transactions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    stats: {},
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload.data
      })
  }
})

export default appUsersSlice.reducer
