// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'


export const getData = createAsyncThunk('subscription/getData', async params => {
  const response = await axios.get('/subscription/', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const cancelSubscription = createAsyncThunk('subscription/cancelSubscription', async params => {
  const response = await axios.post('/subscription/cancel/', params)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getStats = createAsyncThunk('subscriptions/getStats', async params => {
  const response = await axios.get('/subscription/stats/', { params })
    return {
      data: response.data
    }
  })

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    stats: {},
    cancelResponse: null,
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload.data
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        cancelResponse = action.payload
      })
      // .addCase(getUser.fulfilled, (state, action) => {
      //   state.selectedUser = action.payload
      // })
  }
})

export default subscriptionsSlice.reducer
