export default {
    // currentDomain: "https://paynexa.co/",
    // apiEndPoint: "https://api.paynexa.co",
    currentDomain: "http://localhost:3000/",
    apiEndPoint: "http://localhost:8094",
    version:"some-my-version",
    firebaseConfig: {
        apiKey: "AIzaSyAbPV0xPtvW3JHomNpCP1U5Y5bI0dl7FlI",
        authDomain: "paynexa-prod.firebaseapp.com",
        projectId: "paynexa-prod",
        databaseUrl: "https://paynexa-prod-default-rtdb.europe-west1.firebasedatabase.app/",
        storageBucket: "paynexa-prod.appspot.com",
        messagingSenderId: "235666690671",
        appId: "1:235666690671:web:3d6a9b341d58eeee32739a",
        measurementId: "G-HCCZD38R3B"
    }
}

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyAbPV0xPtvW3JHomNpCP1U5Y5bI0dl7FlI",
//     authDomain: "paynexa-prod.firebaseapp.com",
//     projectId: "paynexa-prod",
//     storageBucket: "paynexa-prod.appspot.com",
//     messagingSenderId: "235666690671",
//     appId: "1:235666690671:web:3d6a9b341d58eeee32739a",
//     measurementId: "G-HCCZD38R3B"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
